import { UpsellProps } from '@/hooks/useUpsell'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import * as Styles from './styles'

export default function UpsellModalSeasonPauseContent(props: UpsellProps) {
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  return (
    <>
      <Styles.MarketingBlock>
        <Styles.MarketingContainer padded={true} data-theme="dark">
          <Styles.ImageContainer>
            <Styles.MarketingImage src="/lower-background-seasonal-pause.png" alt="" fill />
          </Styles.ImageContainer>
        </Styles.MarketingContainer>
      </Styles.MarketingBlock>
      <Styles.ContentBlock padded={true}>
        <Styles.ContentRow>
          <Styles.ContentTitle fontSize="3">
            {currentConfig.API.text['seasonal.pause.subscription.header']}
          </Styles.ContentTitle>
          <Styles.ContentSubtitle fontSize="1">
            {currentConfig.API.text['seasonal.pause.subscription.message']}
          </Styles.ContentSubtitle>
        </Styles.ContentRow>
        <Styles.ContentRow>
          <Styles.Action>
            <Styles.ButtonLink buttonSize="sm" buttonStyle="primary">
              <a href={currentConfig.API.text['bally_sports_help.url']}>
                {currentConfig.API.text['seasonal.pause.subscription.button']}
              </a>
            </Styles.ButtonLink>
          </Styles.Action>
        </Styles.ContentRow>
      </Styles.ContentBlock>
    </>
  )
}
