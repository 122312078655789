import ZipcodeInput from '@/components/ZipcodeInput'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { isValidZipcode } from '@grandstand-web/bally-web-shared/src/utils/stringUtils'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as Styles from './styles'

export const UpsellForm = () => {
  const userService = useContext(UserServiceContext)
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const { currentUser } = userService
  const prevZip = useRef<string | undefined>()
  const [zipcode, setZipcode] = useState<string>(currentUser?.profile.region.zip_code ?? '')
  const city = useMemo(() => currentUser?.profile?.region?.city ?? '', [currentUser?.profile?.region?.city])
  const locale = useMemo(() => `${city}, ${zipcode}`, [city, zipcode])
  useEffect(() => {
    const prev = currentUser?.profile.region.zip_code
    if (prev === undefined) {
      return () => {}
    }
    prevZip.current = prev
    setZipcode(prev)
  }, [currentUser?.profile.region.zip_code])

  // keep remote zipcode up to date when local
  useEffect(() => {
    const prev = prevZip.current ?? ''
    const next = zipcode
    const valid = isValidZipcode(next)
    if (!valid || prev === next) {
      return () => {}
    }

    const timeout = setTimeout(() => {
      userService.updateZipcode(next)
    }, 250)

    return () => {
      clearTimeout(timeout)
    }
  }, [userService, zipcode])

  const handleZipcode = (next: string) => {
    setZipcode(next)
  }
  return (
    <Styles.FormRow>
      <Styles.ZipForm>
        <ZipcodeInput onChange={handleZipcode} value={zipcode} />
        <Styles.ZipHelper fontSize="0">
          <div>
            Your <b>Home Zip Code</b> determines what teams and games are available to you. Need help?{' '}
            <Styles.InlineLink
              target="_blank"
              href={`${
                currentConfig.API.text['bally_sports_help_update_zip_code.url'] ??
                'https://help.ballysports.com/Answer/Detail/000001369'
              }`}
            >
              Contact Customer Care
            </Styles.InlineLink>
            .
          </div>
        </Styles.ZipHelper>
      </Styles.ZipForm>
    </Styles.FormRow>
  )
}
